/* eslint-disable prettier/prettier */
import { LogLevel } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId:  process.env.REACT_APP_AZURE_APP_ID,  
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      }
    }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);
export const hemetricsAPIConfig = {
 //deda5990-536c-45db-855d-0ad97185a559 hemetrics-qa-api
    // 42977feb-90ae-4ef9-a200-464dc237c315 localhost
    scopes: [`api://${process.env.REACT_APP_HEMETRICS_CLIENT_ID}/user_impersonation`] 
};

export const graphConfig = {
  scopes: ['people.read user.readbasic.all user.read'],
  baseURL: 'https://graph.microsoft.com/v1.0'
};
export const emailAPIConfig = {
  scopes: ['api://95edf610-481a-4131-aea0-4cfb39c3a004/email.send'], //uat
  //baseURL: 'http://localhost:63804'
  baseURL: process.env.REACT_APP_EMAIL_ENDPOINT // 'https://nybce-email-notification-qa-api.azurewebsites.net'
};
export const fileShareAPIConfig = {
  scopes: ['api://94baf7e5-ec28-48ac-9c95-a0163e02f871/user_impersonation'],  // uat
  //baseURL: 'http://localhost:63804'
  baseURL: process.env.REACT_APP_FS_ENDPOINT // 'https://api-fileshare-uat.capps.nybce.org'
};
export const notificationAPIConfig = {
  scopes: ['api://ee51b530-8c98-4fa8-9cd4-26a384af162e/user_impersonation'], // notification scope
  baseURL: process.env.REACT_APP_NOTIFICATION_ENDPOINT
  // baseURL: 'https://nybce-application-notification-qa-api.azurewebsites.net'  // from environment variable
};
export const powerBiAPIConfig = {
  baseURL: process.env.REACT_APP_POWERBI_ENDPOINT, // 'https://nybce-powerbi-qa-api.azurewebsites.net'
  scopes: ['api://9f380a14-4460-4ebe-b3bf-da37d003d6a6/fetch_report'],
};
export const boldReportConfig = {
  server: 'https://hemetrics-report.azurewebsites.net'
}
